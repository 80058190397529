import {useEffect} from 'react';
import {navigate} from 'gatsby';

const NotFoundPage = () => {
  useEffect(() => {
    return navigate('/');
  }, []);

  return null;
};

export default NotFoundPage;
